import React from 'react'
import Style from './Terms.module.css'

const Terms = () => {
  return (
    <>
      <div className='Navbar__Height'></div>
      <div className={Style.Terms__Page__Section}>
        <div className={Style.Header__Section}>
          <div className='container'>
            <h1 className={Style.Page__Title}>Terms of Use</h1>
          </div>
        </div>
        <div className={Style.Content__Section}>
          <div className='container'>
            <p>Welcome to the Payyit website, an online service designed and offered by Payyit Inc. and its affiliates (“Payyit”) especially for small businesses around the globe. These Terms of Use are intended to explain our obligations as a service provider and your obligations as a user and subscriber. IT IS IMPORTANT THAT YOU READ ALL THE TERMS AND CONDITIONS CAREFULLY.</p>

            <p>If you use this website, you are agreeing to be bound by these Terms of Use without any modification or qualification. IF YOU ARE DISSATISFIED WITH THE TERMS, CONDITIONS, RULES, POLICIES, GUIDELINES OR PRACTICES OF OPERATING OUR SERVICE, UNLESS EXPRESSLY SET OUT IN THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE. If for any reason you are unable to meet all the conditions set forth in these Terms of Use, or if you breach any of the Terms of Use contained herein, your permission to use Payyit or access any of Payyit 's Services (defined below) immediately lapses and you must destroy any materials downloaded or printed from the Payyit website.</p>

            <p>Payyit offers a number of Additional Services each with their own Terms of Service (“Specific Additional Service Terms”) applicable in addition to these Terms of Use. When you use an Additional Service, you will be subject to the Specific Additional Service Terms. Note that if these Terms of Use are inconsistent with the Specific Additional Service Terms, those Specific Additional Service Terms will control.</p>

            <p>Payyit reserves the right to modify or change these Terms of Use at any time without giving prior notice. We shall, however, notify you of significant changes by posting an announcement on the Site. Your use of Payyit is subject to the most current Terms of Use posted on the Site at such time. The most current version can be reviewed by clicking “Terms of Use” hyperlink at the bottom of our Site pages.</p>

            <h4>A. Definitions.</h4>

            <ul>
              <li>“Agreement” or “this agreement” refers to these Terms of Use.</li>
              <li>“Applicable law” refers to the laws currently in force in the Los Angeles, California, U.S.A. which shall govern this agreement.</li>
              <li>“Site” refers to this website, all related webpages, and all related websites operated by affiliates or divisions of Payyit, but does not include any third party websites which are linked to or may link from this website whether or not such third party websites are used in connection with the Services.</li>
              <li>“Service” and “Services” refer to the online financial services and other small business-related services and Support offered through Payyit from time to time.</li>
              <li>“Subscriber” refers to any person who maintains an account with us and utilizes our Services.</li>
              <li>“Support” refers to technical support and assistance provided to users by Payyit.</li>
              <li>“Payyit”, “we”, “us” and “our” shall refer to Payyit Inc. and all its affiliates and subsidiaries.</li>
              <li>“Payyit” refers to the Site and all mobile applications and other applications which provide access to the Services offered by Payyit Financial and its affiliates from time to time.</li>
              <li>“Payyit Parties” includes Payyit, its affiliates, subsidiaries, partners, service providers, suppliers and contractors and each of their respective officers, directors, agents, and employees.</li>
              <li>“Payyit Pros” means the persons and entities who have voluntarily registered with Payyit to be listed on the Site as members of the Payyit Pro Network in order to offer, for their own account, various technical support, financial consulting, accounting and bookkeeping services to Payyit users.</li>
              <li>“Payyit Pro Network” means the online community of Payyit Pros listed on the Site from time to time.</li>
              <li>“You” and “user” shall refer to any person who visits the Site or uses Payyit and includes a subscriber and its employees and agents.</li>
            </ul>

            <h4>B. General.</h4>

            <p>Payyit grants you a non-exclusive, non-transferable limited license to use Payyit and related resources in accordance with these Terms of Use. You agree not to infringe on our intellectual property and the intellectual property of any third parties with whom we have partnerships, and you will comply with the terms of any applicable license agreements.</p>

            <p>You agree that any notices, disclosures, agreements and other communications that we may deliver or communicate to you from time to time comply with the terms of any applicable electronic documents legislation, including the requirement that such documents be in writing, are capable of being retrieved, reviewed, printed and stored for further use by you.</p>

            <h3>Electronic Delivery</h3>

            <p>By accepting this Agreement, you agree and consent to receive electronically all communications, agreements, documents, notices and disclosures (collectively, “Communications”) that we provide in connection with your Account and your use of the Service. Communications include but are not limited to:</p>

            <ul>
              <li>agreements and policies, such as this Agreement and our Privacy Policy, including updates thereto;</li>
              <li>annual disclosures;</li>
              <li>transaction receipts or confirmations;</li>
              <li>communication in relation to delinquent accounts (which may also be by phone, and may be made by Payyit or by anyone on its behalf, including a third party collection agent);</li>
              <li>account statements and history; and</li>
              <li>federal and state tax statements.</li>
            </ul>
            <p>We will provide these Communications to you by emailing them to you at the primary email address listed in your Account registration, by emailing you a link or instructions how to access them on a website, or (if permitted by law) by posting them on the Website. Communications are considered received by you within 24 hours of the time they are emailed to you or posted to our Website. You further agree that your electronic signature has the same effect as your manual, physical signature.</p>
            <p>By giving your consent, you are confirming that you are able to receive, open, and print or download a copy of any Communications for your records. It is important for you to retain copies of Communications because they may not be accessible in your Account at a later date.</p>
            <h3>How to Withdraw Your Consent</h3>
            <p>You may withdraw your consent to receive Communications electronically by contacting us through Customer Support or as outlined in our Privacy Policy. If you withdraw your consent to receive Communications electronically, Payyit Financial may deny your registration for an Account, restrict or close your Account, or charge you additional fees for paper copies.</p>
            <h3>Collaboration</h3>
            <p>You may provide access to certain apps to authorized users (called Guest Collaborators). An authorized user is someone designated by you via the Guest Collaborator functionality available in Payyit. Such authorized users may have access to your account information and perform various tasks as instructed by you which include but are not limited to creating invoices, categorizing transactions, and any other lawful tasks that you may prescribe. If you administer your Payyit account on behalf of a business or corporation, you represent and warrant that you have the right to provide access to your Guest Collaborators.</p>
            <p>Payyit may, without notice or liability, add, discontinue or revise any aspect, mode or design of the Services which include but not limited to the scope of service, time of service, or to the software/hardware required for access to the Services. Payyit may also limit the geographic locations or jurisdictions where certain Services may be available.</p>
            <p>Without prejudice to the foregoing, should there be a failure of or error, omission, defect, deficiency, delay causing downtime, or inability of a subscriber to access the Services for any length of time, including as a result of the permanent termination of service, the subscriber acknowledges and agrees that, except as otherwise set out herein, its only remedy for any error, omission, defect, deficiency, delay or other failure of Payyit or the Services whatsoever is to discontinue using Payyit and the Services.</p>
            <p>You assume sole responsibility for and shall ensure that any information, data, documents or materials used, disclosed, entered into or created using Payyit or in connection with the Services is accurate, reliable and complete and does not violate our content guidelines. You authorize us and our service providers and their vendors and subcontractors to monitor your website and your use of the Services. You represent and warrant that you have obtained all required consents and comply with all applicable legislation, including without limitation privacy legislation, in connection with any use and disclosure of information relating to the use of Payyit and the Services. Payyit accepts no responsibility for the accuracy of any information, data, documents or materials entered into or created using Payyit or the Services except as otherwise set out herein. The input, posting or uploading of any information and the storage of any information, data, documents or materials on the Service by us does not constitute our endorsement nor warranty as to the compliance of such information or materials with applicable legislation, nor to the accuracy, timeliness, materiality, completeness, or reliability of such information or materials. </p>
            <h3>Other Information You Provide Us</h3>
            <p>While using the Services, we may also collect information from you about someone else. If you provide us with personal information about someone else, you are obligated to ensure that you are authorized to disclose that information to us, and that we, without further action, may collect, use, and disclose that information for the purposes described in this policy.</p>
            <p>Payyit may require you to assist with any requests by the individual to access or update their information, and you agree to do so.</p>
            <h4>C. Conditions of Usage.</h4>
            <p>You agree to use Payyit and the Services for the purposes that they were intended and only in accordance with the terms of this agreement.</p>
            <p>If you register an account, you agree, represent and warrant that you will provide us with true, current, complete and accurate information requested in the registration form and from time to time in connection with your use of Payyit. Your registration for any of Payyit constitutes consent to use any personal information which you provide us in such registration for the purposes set out in our Privacy Policy and for all uses ancillary thereto for which your consent may reasonably be implied. You also will select a username and password. You are responsible for keeping your user name, password, account details, and all information required in connection with your use of Payyit ( <em>e.g.</em>&nbsp;employee information if you use Payyit Payroll) confidential and up to date. If you are not the business owner and are registering an account on behalf of a business, you represent and warrant that you have been authorized and directed by your business's principals to open the account and that you have shared all user names, passwords and access credentials with other authorized representatives in your business and that you have provided us with the business contact information of at least one other person. If you leave the business and your business&rsquo;s principal contacts us we may be required to provide access to the Payyit account. You agree to hold harmless and release the Payyit Parties from any liability if we do so. </p>
            <p>Furthermore, you are entirely responsible for any activities that occur under your account. You agree to notify us immediately of any unauthorized access to or use of your account. You agree to hold harmless and release the Payyit Parties from any loss or liability whatsoever that you may incur as a result of someone other than you using your username, password or account, either with or without your knowledge. You agree to indemnify the Payyit Parties for any damages, third party claims or liabilities whatsoever that we may incur as a result of activities that occur on or through your account, whether or not you were directly or personally responsible.</p>
            <p>We are responsible for the security of Cardholder Data that is collected, transmitted, stored, or processed by Us on Your behalf. &ldquo; <strong>Cardholder Data</strong>&rdquo; is defined as being the cardholder&rsquo;s primary account number, and where a full unmasked card number is present, any of the cardholder name, expiration date, and/or service code. Payyit has developed strict security features to protect Cardholder Data, and as such it is required that this data only be used in anticipated ways and stored in appropriate places. </p>
            <p>YOU ACKNOWLEDGE AND AGREE THAT YOU ARE PROHIBITED FROM ENTERING CARDHOLDER DATA INTO ANY FORM OR DATA ENTRY FIELDS IN PAYYIT, EXCEPT INTO THOSE FIELDS INTENDED SOLELY FOR THAT PURPOSE ( <em>i.e.</em>&nbsp;where Payyit explicitly requests such data be entered into such fields). </p>
            <p>Appropriate fields are clearly marked with labels such as &lsquo;Card number&rsquo; or by having a credit card icon precede them. For clarity, it is not permissible to store cardholder data in fields with labels such as &lsquo;memo&rsquo; or &lsquo;note&rsquo;. Similarly, excluding payment forms, you must never enter CVV2 data into any fields in Payyit. You assume all responsibility for any cardholder data entered into Payyit in violation of this Agreement.</p>
            <h4>D. Online Access to Financial Institutions, Payyit Pros and Other Services.</h4>
            <p>Certain online services (including access to online banking, online payments, online invoicing, digitization, storage of receipts, certain payroll related services, the Payyit Pro Network and other online services related to the use of the Site) are provided by financial institutions and other third party service providers and not by us. You agree to hold harmless and release the Payyit Parties from any liability relating to your use of such online services, including the Payyit Pro Network and the Payyit Pros. Your access to and conditions of use of such services may be limited in accordance with the terms of use published by such third party provider. You represent and warrant that you have the right to provide us with usernames, passwords, personal information and other access credentials which we may require to access any third party services or accounts in connection with the Services.</p>
            <p>Payyit does not provide professional accounting services. If you use the Payyit Pro Network, you agree that you do so at your own risk. Unless expressly stated to the contrary, Payyit does not pre-qualify, review, or endorse Payyit Pros and we provide no representation or warranty as to the background, reputation, character, qualifications, skills, insurance, work product, services, advice, recommendations or quality of the Payyit Pro. If you use a Payyit Pro, you acknowledge that you are doing so at your own risk and hereby agree to release and hold Payyit harmless from any or Claims arising from your use of the Payyit Pro, as further set out in Section I below. Payyit does not purport to provide you with any financial or accounting advice and you agree to release and hold Payyit harmless for and from any Claims (as defined below) arising from your use of a Payyit Pro to provide you with technical support in relation to Payyit, as further set out in Section I below.</p>
            <h4>E. Software Notice.</h4>
            <p>In the event that a user is required to download or use software in connection with the Services, he or she shall be unable to download and access such software unless he or she first agrees to the license agreement relating to such software. Use of any such software is governed by these Terms of Use and any such license agreement.</p>
            <h4>F. Copyrights, Trademarks and Intellectual Property.</h4>
            <p>Payyit and its licensors and suppliers own both the proprietary rights as well as the intellectual property rights to all URLs, materials, products, web content, web page designs, web page layouts, images, text, tools, utilities and software that make up the Services, but excluding your data and information which you provide to us or input using Payyit and the Services. The technical procedures, processes, concepts and methods of operation that are inherent within Payyit constitute trade secrets. The usage of our Services does not constitute a sale or transfer of any intellectual property rights to the users. Without any prejudice to the foregoing, any information or data entered using Payyit by a user or otherwise provided for accessing Payyit on the user&rsquo;s behalf shall at all material times remain the property of the user. The user hereby grants to Payyit a worldwide, perpetual, royalty free, nonexclusive right and license to use all content provided by the user in connection with Payyit and the Services for uses related to the delivery of the Services. <strong>
              <br />
            </strong>
            </p>
            <p>Materials on and relating to Payyit, including the content of Payyit and any software downloaded from Payyit, are protected by copyright, trade-mark and other intellectual property laws. Subject to your ownership of your user content and data, Payyit reserves all rights in and to such materials. The subscriber will not make store, download, transfer, sell, reproduce, redistribute, transfer to any other server, modify, reverse engineer or copy the Services or any of the materials or software or any part of Payyit or any content therefrom without Payyit&rsquo;s express written consent. You will also take all reasonable steps to forestall any unauthorized use, copying or transfer of materials on or relating to Payyit.</p>
            <p>Notwithstanding the above, you may, subject to the following conditions, use individual screen displays (screenshots) which you generate as a subscriber using the Services. Your use of screenshots is subject to the following: </p>
            <ul>
              <li>no screenshot may be used from any beta version of Payyit unless it has been commercially released to the public;</li>
            </ul>
            <ul>
              <li>the use is for illustrative purposes;</li>
            </ul>
            <ul>
              <li>the use may not imply any endorsement or affiliation by or with Payyit Financial;</li>
            </ul>
            <ul>
              <li>the screenshot does not contain any commentary which may appear to have been attributable to us;</li>
            </ul>
            <ul>
              <li>the screenshot does not contain any third party content; and</li>
            </ul>
            <ul>
              <li>the use does not infringe on any of these Terms of Use.</li>
            </ul>
            <p>Payyit has rights to several trade-marks which it uses in connection with the operation of Payyit. Payyit does not grant the users any right or license to use the Payyit trade-marks or any logo, trade-name or other intellectual property other than as expressly set out herein and in other licenses between you and us.</p>
            <h4>G. Prohibited Use.</h4>
            <p>The users shall access the information stored using the Services for lawful purposes only and may not use such information for the purpose of committing or furthering fraudulent acts or for committing any acts that would give rise to both or either civil and criminal liability.</p>
            <p>The users agree not to publish, post, upload, distribute, provide or enter any material or information that is illegal, unlawful or can be regarded as fraudulent, libelous, malicious, threatening, offensive, profane, obscene, fanning ethnic or racial tensions, immoral or any such information which any reasonable person would consider objectionable on grounds of good conscience.</p>
            <p>No user shall use any means to restrict or prevent another user from accessing or enjoying Payyit.</p>
            <p>No user shall be permitted to upload material into Payyit that he or she ought to know infringes on the intellectual property of others, or upload material that places unnecessary load as to affect the performances of our websites, systems and equipment. You may not use Payyit and the Services in a manner which could block access to, impair, damage or otherwise disable Payyit or any of our servers. You may not attempt to gain unauthorized access to Payyit or to any other user&rsquo;s accounts, computer systems or networks through password mining, keystroke logging, hacking or any other means. You will not upload any files that contain viruses, Trojan horses, malware, spyware, worms, corrupted files or any other material or software that may damage the operation of another computer. Any and all materials uploaded are subject to applicable laws. </p>
            <p>No user shall lease, sell, pledge, sublicense, assign or otherwise deal with the software belonging to Payyit in a manner that is inconsistent with our intellectual property rights over the software.</p>
            <p>No user shall promote any commercial interest, falsify or delete any information on Payyit, collect personal information without express authority to do so, violate any applicable laws, create a false identity or utilize Payyit under false pretenses.</p>
            <h4>H. Limitation of Liability.</h4>
            <p>THE SUBSCRIBERS AND THE USERS HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE THE PAYYIT PARTIES FROM ANY AND ALL MANNER OF RIGHTS, LOSSES, COSTS, CLAIMS, COMPLAINTS, DEMANDS, DEBTS, DAMAGES, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES, OBLIGATIONS, LEGAL FEES, COSTS AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, INCLUDING WITHOUT LIMITATION ANY LOST PROFIT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES (COLLECTIVELY, A "CLAIM") WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM, RELATE TO, OR ARE CONNECTED WITH (I) THIS AGREEMENT; (II) THE USE OF PAYYIT AND ITS SERVICES AND ANY RELATED APPLICATIONS INCLUDING THE PRO NETWORK AND THIRD PARTY SERVICES; (III) THE USE OF ANY SOFTWARE RELATED TO PAYYIT; (IV) VIRUSES, SPYWARE, SERVICE PROVIDER FAILURES OR INTERNET ACCESS INTERRUPTIONS; (V) LOSS OF USE, LOSS OF DATA, ERROR, INACCURACY OF DATA, PAYMENT FAILURE, PAYMENT DEFECT, INACCURATE CALCULATIONS (EXCEPT AS OTHERWISE SET OUT HEREIN), DOWNTIME, IDENTITY THEFT, FRAUD OR UNAUTHORIZED ACCESS; OR (VI) ANY INFORMATION, DOCUMENT, MATERIALS, TOOLS, UTILITIES, PRODUCT OR DATA THAT RELATING TO THE USE OF PAYYIT AND THE SERVICES; WHETHER SUCH CLAIM IS BASED IN CONTRACT OR TORT OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH CLAIM, OR SUCH CLAIM WAS REASONABLY FORESEEABLE AND NOTWITHSTANDING THE SUFFICIENCY OR INSUFFICIENCY OF ANY REMEDY PROVIDED FOR HEREIN OR IN ANY LICENSE.</p>
            <p>FOR GREATER CERTAINTY, IN NO EVENT SHALL WE (AND OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE SITE OR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, PAYYIT AND SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE, MISUSE, ERROR, OR LOSS OF DATA ARISING FROM SUCH ACCESS AND USE.</p>
            <p>EXCEPT IN THE MANNER PROVIDED FOR IN THESE TERMS OF USE, PAYYIT, ITS LICENSEES, AFFILIATES, AND THIRD PARTY SERVICE PROVIDERS, DISCLAIM, AND EXPRESSLY DO NOT PROVIDE ANY DIRECT OR INDIRECT, EXPRESS OR IMPLIED REPRESENTATION OR WARRANTY AS TO TITLE, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY, OR MERCHANTABILITY OR MERCHANTABLE QUALITY; OR THAT PAYYIT, THE SERVICES, ANY THIRD PARTY SERVICES AND ONLINE SERVICES, AND ANY CONTENT, MATERIALS, TOOLS, UTILITIES, SOFTWARE AND FUNCTIONALITY WILL: (I) BE FIT OR SUITABLE FOR ANY SPECIFIC PURPOSE OR INTENDED USE; (II) MEET THE USERS' REQUIREMENTS AND EXPECTATIONS; (III) BE UNINTERRUPTED, FLAWLESS, TIMELY, ACCURATE, RELIABLE, SECURE OR ERROR FREE, OR; (IV) BE FREE OF VIRUSES OR OTHER HARMFUL ELEMENTS. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT PAYYIT AND THE SERVICES ARE PROVIDED ON AN "AS IS" BASIS AND YOU AGREE TO USE PAYYIT, THE SERVICES, ANY THIRD PARTY SERVICES AND ONLINE SERVICES, AND ANY CONTENT, MATERIALS, TOOLS, UTILITIES, SOFTWARE AND FUNCTIONALITY RELATED TO PAYYIT AT YOUR OWN RISK. YOU AGREE TO DETERMINE THE SUITABILITY OF PAYYIT AND SERVICES FOR YOUR INTENDED USE AND AGREE TO VERIFY ALL RESULTS OBTAINED USING PAYYIT AND THE SERVICES.</p>
            <p>IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE 1542 IN CONNECTION WITH ANY RELEASE PROVIDED BY YOU IN THESE TERMS OF USE, INCLUDING THE FOREGOING, WHICH SECTION STATES: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR."</p>
            <h4>I. Termination of Service.</h4>
            <p>Payyit may terminate this agreement and your use of Payyit at any time and for any reason with reasonable notice to you and without notice if you breach any of these terms or we suspect you of fraud or suspect that your Account has been compromised in any way. Subscribers may use Payyit on an at-will basis until the agreement has been terminated by either party pursuant to the terms of this agreement or any related license agreement. You may also terminate this agreement by following the &ldquo;Close your Payyit Account&rdquo; instructions found on your Account page. In the case of a company, upon an order or a resolution being passed for the purposes of winding up the business activities other than for the purposes of amalgamation, merger or reconstruction or upon a composition agreement being made with the creditors. </p>
            <p>Upon such termination, you must immediately cease using Payyit indefinitely. We may, at our discretion, block your access to Payyit.</p>
            <h4>J. Ownership and Disclosure of Information.</h4>
            <p>You own all of your business&rsquo;s private data, content and all information which you enter and use in connection with the Services. We do not claim any rights, proprietary or otherwise over any data or information which you may use or disclose in connection with the Service and Payyit. Notwithstanding the above, there may be circumstances in which we may be required to disclose data, such as the following:</p>
            <ul>
              <li>for the purposes of fraud prevention and law enforcement;</li>
            </ul>
            <ul>
              <li>to comply with any legal, governmental or regulatory requirement;</li>
            </ul>
            <ul>
              <li>to our lawyers in connection with any legal proceedings; and</li>
            </ul>
            <ul>
              <li>to comply with a court order.</li>
            </ul>
            <p>If we are required to disclose your data or information, we will use our best efforts to provide you with reasonable notice in the circumstances and if appropriate the right to challenge any such request. Payyit Financial's Privacy Policy sets out in greater detail how we strive to protect your privacy and limit disclosure of your personal information.</p>
            <h4>K. Third Party Links, APIs and Content.</h4>
            <p>You may when using our Site and Services be directed to websites maintained by other third party service providers.</p>
            <p>Some of the functionality of the Services and Payyit interoperate with, and are highly dependent upon, application programming interfaces (APIs) from third parties, such as Google and Facebook. If at any point such third party services cease to make themselves or their APIs available to us on reasonable terms, we may cease to provide those third party services without entitling you to a refund, credit or other compensation. Your only recourse shall be to stop using Payyit and the Services. In addition, if you authorize us to do so, we may grant third parties access to some or all (depending on the permission you give) of your private data, content and information through our own API for use in connection with their services.</p>
            <p>You acknowledge that such sites and services are completely independent of Payyit and as we have no control over them, we accept no liability in respect of your use, ability or inability to use them or any of the content of such sites. In addition, we may at any time in our discretion and without notice to you, discontinue providing our API to such third party services. You acknowledge that any use of the products and services offered by such third party services providers ( <em>e.g.</em>&nbsp;for the purposes of payment processing, direct deposit services, payroll tax return preparation, filing and government remittances) will be at your sole risk. You acknowledge that use of such third party service providers and their websites and services is, except where prohibited or modified by applicable law, subject to the terms, conditions and policies established by the third party service providers. You expressly hold us harmless and hereby release us from any liability whatsoever whether arising out of contract, tort or otherwise for and from any Claims (defined below) arising out of your use of, or inability to use, the products and services of third party service providers whether or not such use is ancillary to your use of Payyit. The availability of such third party services in connection with Payyit does not constitute an endorsement, warranty, or representation as to the fitness, suitability, merchantability, title, non-infringement, quality, or accuracy of the third party provider or its products or services. </p>
            <h4>L. Authorization to Export Data.</h4>
            <p>The subscriber and the user acknowledge that we may retain a copy of his/her/its transactional information gathered through the use of Payyit and other information uploaded on the subscriber's account. We may in certain circumstances export user information outside the city of Los Angeles and outside of the United States of America. We shall not share personal information with anyone except in the manner provided in our&nbsp;<a href="https://payyit.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>
            <h4>M. Force Majeure.</h4>
            <p>Neither party should be held liable for a delay or failure in performance of the agreement for services caused by reason of any occurrence of unforeseen event beyond its reasonable control, including but not limited to, acts of God, natural disasters, power failures, server failures, third party service provider failures or service interruptions, embargo, labour disputes, lockouts and strikes, riots, war, floods, insurrections, legislative changes, and governmental actions. The party so affected by the unforeseen event shall be so excused on a day-to-day basis for the period of time equal to that of the underlying cause of delay.</p>
            <h4>N. Severance.</h4>
            <p>All provisions of these Terms of Use are, notwithstanding the manner in which they have been grouped together or linked grammatically, are severable from each other. If any of these Terms of Use should be determined to be unenforceable the remaining Terms of Use shall survive and remain in full force and effect and continue to be binding and enforceable.</p>
            <h4>O. Governing Law and Dispute Resolution.</h4>
            <p>Except as otherwise set out herein, this Agreement shall in all respects be governed by and interpreted, construed and enforced in accordance with the laws of Los Angeles, California and the laws of the United States of America applicable therein as applied to agreements entered into and to be performed entirely within Los Angeles, California, without regard to its choice of law or conflicts of law principles that would require application of law of a different jurisdiction. This agreement and any actions whatsoever taken by you in connection herewith and with any Service, software, tool, application or functionality, will be deemed to have been performed in Los Angeles, California. The parties hereto irrevocably submit and attorn to the exclusive jurisdiction and venue of the courts of Los Angeles, California.</p>
            <p>You waive all rights to a trial by jury in connection with any legal proceeding or dispute against us. You further agree that any dispute or proceeding which you may bring against us shall be conducted on an individual basis and not a class-wide basis and that any such proceeding or dispute shall not be consolidated with any other dispute or proceeding which might arise between us and any other user.</p>
            <h4>P. Legal Status of Payyit Inc.</h4>
            <p>The website <a href="https://payyit.com" target="_blank" rel="noreferrer">www.payyit.com</a> and the names Payyit, Payyit, Payyit Accounting, Payyit Payroll, and Payyit Apps are property of Payyit Inc. a company incorporated in the state of Delaware.  </p>
            <h4>Q. Privacy Policy.</h4>
            <p>Payyit's <a href="https://payyit.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> is hereby incorporated into this Agreement and applies to the collection, use, disclosure, retention, protection and accuracy of your personal information and your business' financial information (the “Information”) collected for the purposes of the Services offered through our website. The Payyit Financial Privacy Policy is based on applicable legislation as well as 10 fair information principles, and may be updated from time to time. </p>
            <h4>R. Information Received from Third Party Services.</h4>
            <p>If you elect to have Payyit access information from third party accounts by providing the access information to such third party account, one of our service providers, Plaid Inc. (“Plaid”) or Envestnet Yodlee, Inc. (“Yodlee” and, together with Plaid, the “Bank Data Providers”), will access such third party accounts for the purposes of performing the Services. This can include aggregation of bank transaction data as well as account verification. By providing the requisite access information to Payyit, you grant us and our Bank Data Providers permission and authority to access the relevant third party accounts, on your behalf or on behalf from the principal account holder, and retrieve the relevant information to make it available as part of the Services and agree to be bound by our Bank Data Providers terms of use ( <strong>Plaid</strong>&nbsp;and&nbsp; <strong>Yodlee</strong>) in addition to their Terms of Use. You are not permitted to provide Payyit with any access information for any third party accounts for which you are not the principal account holder unless you have the authority to act on behalf of the principal account holder. </p>
            <p>Payyit is not obligated to review the information for any purpose, including but not limited to accuracy. It is entirely your responsibility to ensure the accuracy, completeness and timeliness of any information provided to us or any third party service providers in connection with any of the Services.</p>
            <h4>S. Registration Information.</h4>
            <p>You and your users are responsible for maintaining the confidentiality and security of usernames, passwords and other access credentials which allow the use of Payyit and access to the Services. You understand and agree that the you are liable for unauthorized use of your account.</p>
            <h4>T. Consumer Issues.</h4>
            <p>Unless you subscribe only to our Payyit Personal application, in which case this section does not apply to you, you acknowledge and agree that you will only use Payyit and the Services for business purposes and not for personal, family or household purposes.</p>
            <h4>U. No Waiver.</h4>
            <p>No delay or omission to exercise any right or remedy accruing upon any breach or default hereunder shall impair any such right or remedy nor be construed as a waiver of any such breach or default or of any similar breach or default thereafter occurring, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any other right or remedy. No waiver (or consecutive waivers) of any single breach or default shall operate or be construed as a waiver of any subsequent breach or default. </p>
            <h4>V. Terms for Additional Services.</h4>
            <p>The use of certain Additional Services are subject to and governed by additional terms of service (i.e. the Specific Additional Service Terms, noted in the recitals at the beginning of this agreement). If you use any of the Services listed immediately below, the linked Specific Additional Service Terms apply. In the event such additional or specific terms are inconsistent with the Specific Additional Service Terms, those Specific Additional Service Terms will control.</p>
            <p>
              <b>
                <a href="https://help.solidfi.com/hc/en-us/articles/4405720601755-Solid-Platform-Terms-of-Service" target="_blank" rel="noreferrer">SolidFi</a>
              </b> If you are issued a personal or business checking and/or savings accounts, the Banking by Payyit program is powered by SolidFi, you're bound by their Terms and Conditions.
            </p>
            <p>
              <b>
                <a href="https://payyit.com/merchantagreement" target="_blank" rel="noreferrer">Payyit</a>
              </b> If you are processing credit, debit, and ACH payments in the United States & Canada, Payments by Payyit is powered by Tilled, Commercial Bank, Evolve Bank & Trust, Fresno First Bank, you're bound by their Terms and Conditions. For more information regarding processing fees, visit our <a href="https://payyit.com/pricing">Pricing</a> page.
            </p>
            <p>
              <b>
                <a href="https://go.wepay.com/terms-of-service-us/" target="_blank" rel="noreferrer">WePay</a>
              </b> If you are processing credit, debit, and ACH payments in the United States 🇺🇸, Payments by Payyit is powered by WePay, you're bound by their Terms and Conditions. For more information regarding processing fees, visit our <a href="https://payyit.com/pricing" target="_blank" rel="noreferrer">Pricing</a> page.
            </p>
            <p>
              <b>
                <a href="https://go.wepay.com/terms-of-service-canada" target="_blank" rel="noreferrer">Chase Paymentech Solutions</a>
              </b> If you are processing credit and debit payments in Canada 🇨🇦, Payments by Payyit is powered by Chase Paymentech Solutions, you're bound by their Terms and Conditions. For more information regarding processing fees, visit our <a href="https://payyit.com/pricing" target="_blank" rel="noreferrer">Pricing</a> page.
            </p>
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th>Payment Card Type</th>
                  <th>Processing Method (Card Not Present)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>American Express Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>American Express Prepaid Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>MasterCard Business Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>MasterCard Core Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>MasterCard Corporate Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>MasterCard Debit Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>MasterCard Prepaid Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>MasterCard World Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>MasterCard World Elite Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>Visa Business Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>Visa Infinite Business Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>Visa Consumer Credit Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>Visa Corporate Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>Visa Debit Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>Visa Infinite Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>Visa Infinite Privilege Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>Visa Prepaid Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
                <tr>
                  <td>Discover Cards</td>
                  <td>3.9% + $0.45 CAD</td>
                </tr>
              </tbody>
              <thead class="thead-dark">
                <tr>
                  <th>Other Fees</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Chargebacks</td>
                  <td>$25.00 CAD</td>
                </tr>
              </tbody>
            </table>
            <p>
  <b>
    <a href="https://stripe.com/legal/connect-account" target="_blank" rel="noreferrer">Stripe</a>
  </b> If you are processing credit, debit, and bank payments, Payments by Payyit is powered by Stripe. You're bound by their Terms and Conditions. For more information regarding processing fees, visit our <a href="https://payyit.com/pricing" target="_blank" rel="noreferrer">Pricing</a> page.
</p>
<p>
  <b>
    <a href="https://www.paysafe.com/fileadmin/content/pdf/Mindbody_acquiring_terms_10_05_2016_CC_and_SEPA.pdf" target="_blank" rel="noreferrer">Paysafe</a>
  </b> If you are processing credit, debit, and ACH payments, Payments by Payyit is powered by Paysafe. You are bound by their Terms and Conditions. For more information regarding processing fees, visit our <a href="https://payyit.com/pricing" target="_blank" rel="noreferrer">Pricing</a> page.
</p>
<p>
  <b>
    <a href="https://payarc.com/terms-and-conditions/" target="_blank" rel="noreferrer">PayArc</a>
  </b> If you are processing credit, debit, and ACH payments, Payments by Payyit is powered by PayArc. You are bound by their Terms and Conditions. For more information regarding processing fees, visit our <a href="https://payyit.com/pricing" target="_blank" rel="noreferrer">Pricing</a> page.
</p>
<p>
  <b>
    <a href="https://www.globalpayments.com/-/media/project/global-payments/corporate/corporate/documents/Card-Acceptance-Guide-Jan2021.pdf" target="_blank" rel="noreferrer">Global Payments</a>
  </b> If you are processing credit, debit, and ACH payments, Payments by Payyit is powered by Global Payments. You are bound by their Terms and Conditions. For more information regarding processing fees, visit our <a href="https://payyit.com/pricing" target="_blank" rel="noreferrer">Pricing</a> page.
</p>
<p>
  <b>
    <a href="https://www.paypal.com/us/business" target="_blank" rel="noreferrer">PayPal</a>
  </b> If you are processing credit, debit, and bank payments, Payments by Payyit is powered by PayPal. You're bound by their Terms and Conditions. For more information regarding processing fees, visit our <a href="https://payyit.com/pricing" target="_blank" rel="noreferrer">Pricing</a> page.
</p>
<p>
  <b>
    <a href="https://preferredpayments.com/terms/" target="_blank" rel="noreferrer">Ecrypt</a>
  </b> If you are processing credit, debit, and ACH payments, Payments by Payyit is powered by Ecrypt, also known as Preferred Payments. You are bound by their Terms and Conditions. For more information regarding processing fees, visit our <a href="https://payyit.com/pricing" target="_blank" rel="noreferrer">Pricing</a> page.
</p>
<p>
  <b>
    <a href="https://www.solidfi.com" target="_blank" rel="noreferrer">SolidFi</a>
  </b> If you are issued a Blue Visa Debit card, the Blue Visa Debit card program is powered by SolidFi. You're bound by their Terms and Conditions.
</p>
<p>
  <b>
    <a href="https://plaid.com/legal/#consumers" target="_blank" rel="noreferrer">Plaid</a>
  </b> If you are importing transaction data for accounting purposes, bank data importing is powered by Plaid. You're bound by their Terms and Conditions.
</p>
            <h4>W. Refund Policy for Services and Subscriptions.</h4>
            <p>
              At Payyit, we are committed to providing our customers with transparent and fair refund policies. Please review the terms below to understand our refund process.
            </p>
            <h4>Eligibility</h4>
            <p>
              Refunds are eligible for services and subscriptions under the following conditions:
            </p>
            <ul>
              <li>The refund request is submitted within 7 days of the transaction date.</li>
              <li>The service has not been fully utilized, and the subscription period has not reached its majority usage.</li>
              <li>Refunds are only applicable to unused portions of the services or subscriptions.</li>
            </ul>
            <h4>Non-Refundable Fees</h4>
            <p>
              The following fees are non-refundable:
            </p>
            <ul>
              <li>Setup fees and initial account creation fees.</li>
              <li>Transaction processing fees associated with completed transactions.</li>
            </ul>
            <h4>Processing Time</h4>
            <p>
              Approved refunds will be processed within 7-10 business days from the date of approval. Refunds will be issued using the original payment method whenever possible. If delays occur, customers will be promptly notified.
            </p>
            <h4>How to Request a Refund</h4>
            <p>
              To request a refund, please contact our customer support team at <a href="hello@payyit.com">hello@payyit.com</a>. Provide the following information in your request:
            </p>
            <ul>
              <li>Transaction details (e.g., date, amount, and reference number).</li>
              <li>Reason for requesting the refund.</li>
            </ul>
            <h4>Exceptions</h4>
            <p>
              Refunds may not be granted in cases where:
            </p>
            <ul>
              <li>The service has been fully utilized.</li>
              <li>The subscription period has been completed or is near completion.</li>
              <li>The refund request falls outside the eligible timeframe.</li>
            </ul>

            <p>
              For further inquiries regarding our refund policy, feel free to contact our support team. We strive to resolve all refund requests promptly and fairly.
            </p>
            <h4>X. Contact Information.</h4>
            <p>
            Our office address is 1422 S Tryon St, Charlotte, NC 28203 and email address is <a href="hello@payyit.com">hello@payyit.com</a>.
</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Terms
