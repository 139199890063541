import React, { useEffect } from 'react';
import styles from './Footer.module.css';
import Footer__Logo from '../../assets/images/footer-logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when link is clicked
  };

  return (
    <div className={styles.Footer__Section}>
      <div className='container'>
        <div className='row gx-3 gy-5 g-lg-5 g-xl-5'>
          <div className='col-12 col-lg-3'>
            <figure className='mb-4'>
              <img src={Footer__Logo} alt='' width={'60'} />
            </figure>
            {/* <div className={styles.Social__Links}>
              <a href='/'><i className='fab fa-facebook-f'></i></a>
              <a href='/'><i className="fab fa-instagram"></i></a>
              <a href='/'><i className="fab fa-linkedin-in"></i></a>
              <a href='/'><i className="fab fa-youtube"></i></a>
            </div> */}
          </div>
          <div className='col-12 col-lg-9'>
            <div className='row gx-3 gy-5 g-lg-5 g-xl-5 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4'>
            <div className='col'>
                <div className={styles.Widget}>
                  <h5 className={styles.Widget__Title}>Features</h5>
                  <ul className={styles.Widget__List}>
                    <li><Link to={'/accounting'} onClick={scrollToTop}>Accounting software</Link></li>
                    <li><Link to={'/invoicing'} onClick={scrollToTop}>Invoicing software</Link></li>
                    {/* <li><Link to={'/'}>Payyit app</Link></li> */}
                    {/* <li><Link to={'/'}>Mobile receipts</Link></li> */}
                    <li><Link to={'/payments'} onClick={scrollToTop}>Payments</Link></li>
                    {/* <li><Link to={'/'}>Payroll software</Link></li> */}
                    <li><Link to={'/advisors'} onClick={scrollToTop}>Payyit Advisors</Link></li>
                    <li><Link to={'/pricing'} onClick={scrollToTop}>Pricing</Link></li>
                    <li><Link to={'/recurring-billing'} onClick={scrollToTop}>Recurring billing</Link></li>
                    <li><Link to={'/payyitme'} onClick={scrollToTop}>Payyit.Me Lynk</Link></li>
                  </ul>
                </div>
              </div><div className='col'>
                <div className={styles.Widget}>
                  <h5 className={styles.Widget__Title}>Important Information</h5>
                  <ul className={styles.Widget__List}>
                    <li><Link to={'/terms'} onClick={scrollToTop}>Terms of Use</Link></li>
                    <li><Link to={'/privacy'} onClick={scrollToTop}>Privacy Policy</Link></li>
                    <li><Link to={'/security'} onClick={scrollToTop}>Security</Link></li>
                    <li><Link to={'/protection'} onClick={scrollToTop}>GDPR</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className={styles.Footer__Line} />
        <div className='row align-items-center g-3'>
          <div className='col-lg-12'>
            <p className='mb-0 text-center'>Copyright © 2018 - 2025  Payyit Inc. All Rights Reserved.</p>
            {/* <ul className={styles.Inline__List}>
              <li><Link to={'/'}>Privacy Policy</Link></li>
              <li><Link to={'/'}>Legal Disclosures</Link></li>
              <li><Link to={'/'}>Security</Link></li>
              <li><Link to={'/'}>Licensing</Link></li>
              <li><Link to={'/'}>Accessibility</Link></li>
              <li><Link to={'/'}>Social Media Disclaimer</Link></li>
              <li><Link to={'/'}>Privacy Choices</Link></li>
              <li><Link to={'/'}>Cookies Settings</Link></li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
